export class FeAllocation {
  _id: string;
  locationCode: string;
  pinCode: string;
  city: string;
  locality: string;
  route: string;
  fieldExecutive: string;
  dateCreation: Date;
  dateActive: Date;
  dateExpire: Date;
  enteredBy: string;
  companyCode: string;
  status: string;
  environment: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.locationCode = rawData.locationCode;
    this.pinCode = rawData.pinCode;
    this.city = rawData.city;
    this.locality = rawData.locality;
    this.fieldExecutive = rawData.fieldExecutive;
    this.route = rawData.route;
    this.dateCreation = rawData.dateCreation;
    this.dateActive = rawData.dateActive;
    this.dateExpire = rawData.dateExpire;
    this.enteredBy = rawData.enteredBy;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.environment = rawData.environment;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
