export class CollectionLocation {
  _id: string;
  lab: string;
  customerGroup: string;
  parentLocationCode: string;
  parentLocationName: string;
  collectionLocationCode: string;
  collectionLocationName: string;
  category: string;
  postalCode: string;
  country: string;
  state: string;
  district: string;
  city: string;
  area: string;
  address: string;
  mobileNo: number;
  email: string;
  route: string;
  dateCreation: Date;
  dateActive: Date;
  dateExpire: Date;
  enteredBy: string;
  companyCode: string;
  status: string;
  environment: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.lab = rawData.lab;
    this.customerGroup = rawData.customerGroup;
    this.parentLocationCode = rawData.parentLocationCode;
    this.parentLocationName = rawData.parentLocationName;
    this.collectionLocationCode = rawData.collectionLocationCode;
    this.collectionLocationName = rawData.collectionLocationName;
    this.category = rawData.category;
    this.postalCode = rawData.postalCode;
    this.country = rawData.country;
    this.state = rawData.state;
    this.district = rawData.district;
    this.city = rawData.city;
    this.area = rawData.area;
    this.address = rawData.address;
    this.mobileNo = rawData.mobileNo;
    this.email = rawData.email;
    this.route = rawData.route;
    this.dateCreation = rawData.dateCreation;
    this.dateActive = rawData.dateActive;
    this.dateExpire = rawData.dateExpire;
    this.enteredBy = rawData.enteredBy;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.environment = rawData.environment;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
